import { Controller } from "stimulus";
import { timeService } from "time_service";

export default class extends Controller {
  static targets = [];

  initialize() {
  }

  connect() {
    if (this.timestamp == "") {
      return;
    }

    let formattedLocalTimeStamp = 
      timeService.localise(this.timestamp, this.format, this.includeZone);
      
    $(this.element).html(formattedLocalTimeStamp);
  }

  disconnect() {
  }

  get timestamp() {
    return this.data.get("timestamp");
  }

  get format() {
    return this.data.get("format") || "LLLL"
  }

  get includeZone() {
    return this.data.get("include-zone") == "true"
  }
}
