import moment from "moment-timezone";
import "moment/locale/en-au";
import JSTZ from "jstz";

class TimeService {
  constructor() {
    this.detectTimezone();
  }

  detectTimezone() {
    if (!sessionStorage.getItem('timezone')) {
      let name = "Australia/Sydney";
      var tz = JSTZ.determine();
      
      if (tz) {
        name = tz.name();
      }

      sessionStorage.setItem('timezone', name);
    }
  }

  currentTz() {
    return sessionStorage.getItem('timezone');
  }

  localise(timestamp, format, includeZone = false) {
    var momentTime = moment(timestamp);
    var tzTime = momentTime.tz(this.currentTz());
    if (includeZone) {
      return `${tzTime.format(format)} (${this.currentTz()})`;
    } else {
      return tzTime.format(format);
    }
  }
}

export let timeService = new TimeService();
