import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.cancel = false;
    this.redirect = this.redirect.bind(this);
    this.wait();
  }

  disconnect() {
    this.cancel = true;
  }

  wait() {
    setTimeout(this.redirect, 7000);
  }

  redirect() {
    if (this.cancel) {
      return;
    }
    
    Turbolinks.visit(this.redirectPath, { action: "replace" });
  }

  get redirectPath() {
    return this.data.get("redirect-path");
  }
}
