import { Controller } from "stimulus";
import { eventBus } from "event_bus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  connect() {
    eventBus.addEventListener(`${this.event}:show`, this.onShow);
    eventBus.addEventListener(`${this.event}:hide`, this.onHide);
    this.element.classList.add("hidden");
  }

  disconnect() {
    eventBus.removeEventListener(`${this.event}:show`, this.onShow);
    eventBus.removeEventListener(`${this.event}:hide`, this.onHide);
  }

  onShow() {
    this.element.classList.remove("hidden");
  }

  onHide() {
    this.element.classList.add("hidden");
  }

  get event() {
    return this.data.get("event");
  }
}
