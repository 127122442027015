import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "hostedPageId"];

  initialize() {
    this.createCheckout = this.createCheckout.bind(this);
    this.submit = this.submit.bind(this);
  }

  connect() {
    this.loadScript();
  }

  subscribe(e) {
    this.openDialog(e.target.dataset.plan);
    e.preventDefault();
    e.stopPropagation();
  }

  disconnect() {
  }

  openDialog(plan) {
    this.createCheckout(plan).then(this.submit).catch(error => {
      Chargebee.getInstance().logout();
    });
  }

  createCheckout(plan) {
    Chargebee.init({ site: this.site});

    return new Promise((resolve, reject) => {
      let createHostedPageWithPlan = () => {
        return this.createHostedPage(plan)
      }

      Chargebee.getInstance().openCheckout({
        hostedPage: createHostedPageWithPlan.bind(this),
        success: (hostedPageId) => {
          Chargebee.getInstance().closeAll();
          resolve(hostedPageId);
        }, 
        error: () => {
          reject("error");
        },
        close: () => {
          reject("close");
        }
      });
    });
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      var s = document.createElement('script');
      s.src = 'https://js.chargebee.com/v2/chargebee.js';
      s.async = true;
      s.type = 'text/javascript';
      s.onload = () => {
        return resolve();
      };
      
      this.element.parentNode.insertBefore(s, this.element);
    });
  }

  createHostedPage(plan) {
    return $.ajax({
      url: this.hostedPagePath,
      type: "POST",
      data: { plan_id: plan }
    });
  }

  submit(hostedPageId) {
    this.hostedPageIdTarget.value = hostedPageId;
    this.formTarget.submit();
  }

  get site() {
    return this.data.get("site");
  }

  get hostedPagePath() {
    return this.data.get("hosted-page-path");
  }

  get createSubscriptionPath() {
    return this.data.get("create-subscription-path");
  }
}
