import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!this.inIframe()) {
      window.location = this.redirect;
    }
  }

  get redirect() {
    return this.data.get("redirect");
  }

  inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }
}