import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let href = $(this.element).find("a:last").attr("href");
    
    if (href) {
      this.element.classList.add("clickable-row");
    };

    this.element.addEventListener("click", (e) => {
      if ($(e.target).closest("a").length) {
        return;
      }

      Turbolinks.visit(href);
      return false;
    });
  }
}