import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
  }

  connect() {
  }

  disconnect() {
  }

  open(e) {
    Beacon('open');
    e.preventDefault();
    e.stopPropagation();
  }
}
