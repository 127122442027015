import { Controller } from "stimulus";
import retry from 'async/retry';
import asyncify from 'async/asyncify';
import JobError from 'job_error';

export default class extends Controller {
  static targets = [];

  initialize() {
    this.fetchJob = this.fetchJob.bind(this);
  }

  async connect() {
    this.fetchJobWithRetries();
  }

  disconnect() {
  }

  get jobPath() {
    return this.data.get("path");
  }

  get successPath() {
    return this.data.get("success-path");
  }

  get failurePath() {
    return this.data.get("failure-path");
  }

  async fetchJobWithRetries() {
    let opts = { 
      times: 300,
      interval: 1000,
      errorFilter: (err) => {
        return err.job.status == "busy";
      }
    }

    try {
      await retry(opts, asyncify(this.fetchJob));
      Turbolinks.visit(this.successPath, { action: "replace" });
    } catch(error) {
      Turbolinks.visit(this.failurePath, { action: "replace" });
    }
  }

  async fetchJob() {
    let response = await fetch(`${this.jobPath}.json`, { credentials: 'same-origin' });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    
    let job = await response.json();

    if (job.status == "complete") {
      return job;
    }
    
    throw new JobError(job.status, job);
  }
}
