import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.createPortalSession = this.createPortalSession.bind(this);
    this.waitForChanges = this.waitForChanges.bind(this);
  }

  connect() {
    
  }

  disconnect() {
  }

  open(e) {
    Chargebee.init({ site: this.site});
    
    let instance = Chargebee.getInstance();
    instance.setPortalSession(this.createPortalSession);
    let portal = instance.createChargebeePortal();

    portal.open({
      close: this.waitForChanges,
    });
    
    e.preventDefault();
    e.stopPropagation();
  }

  createPortalSession() {
    return $.ajax({
      url: this.sessionPath,
      type: "POST"
    });
  }

  waitForChanges(data) {
    Turbolinks.visit(this.waitPath, { action: "replace" });
  }

  get site() {
    return this.data.get("site");
  }

  get sessionPath() {
    return this.data.get("session-path");
  }

  get waitPath() {
    return this.data.get("wait-path");
  }

}
